import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import Actualite from '../components/PagesComponent/Actualite/Actualite';

const ActualiteSingle = () => {
  return (
    <Container>
      <SEO
        title="Hairun Technology - Actualité"
        description="Details Actualite"
      />
      <Actualite />
    </Container>
  );
};

export default ActualiteSingle;
